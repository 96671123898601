import baseService from "./baseService";

export default class React extends baseService {
  constructor() {
    super();
    this.schemaName = "React";
    this.schemaNameEducation = "React/Education";
    this.schemaNamePage = "React/Content";
    this.schemaNameAccount = "React/Account";
    this.schemaNameOrganization = "React/Organization";
  }

  getCourseViewType(entity) {
    return this.postObject(this.schemaName, "GetCourseViewType", entity);
  }

  updateCourseViewType(entity) {
    return this.postObject(this.schemaName, "UpdateCourseViewType", entity);
  }

  updateLessonViewType(entity) {
    return this.postObject(this.schemaName, "updateLessonViewType", entity);
  }

  getPage(entity) {
    return this.postObject(this.schemaNamePage, "GetPage", entity);
  }

  getLessonDetail(entity, cancel) {
    return this.postObject(
      this.schemaNameEducation,
      "GetLessonDetail",
      entity,
      cancel
    );
  }

  getLessonDetailLazyLoad(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetLessonDetailLazyLoad",
      entity
    );
  }

  getCourses(entity) {
    return this.postObject(this.schemaNameEducation, "GetCourses", entity);
  }

  getCoursesLazyLoad(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetCoursesLazyLoad",
      entity,
      cancelToken
    );
  }

  getCourseImages(entity) {
    return this.postObject(this.schemaNameEducation, "GetCourseImages", entity);
  }

  getAddNewCourse(entity) {
    return this.postObject(this.schemaNameEducation, "GetAddNewCourse", entity);
  }

  GetAddNewCourseLesson(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetAddNewCourseLesson",
      entity
    );
  }

  updateCourse(entity) {
    return this.postObject(this.schemaNameEducation, "UpdateCourse", entity);
  }

  getImageSets(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetImageSets",
      entity,
      cancelToken
    );
  }

  getOldCourses(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetOldCourses",
      entity,
      cancelToken
    );
  }

  cloneCourse(entity) {
    return this.postObject(this.schemaNameEducation, "CloneCourse", entity);
  }

  getTagListForAdd(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetTagListForAdd",
      entity,
      cancelToken
    );
  }

  updateCourseLesson(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "UpdateCourseLesson",
      entity
    );
  }

  getCourse(entity) {
    return this.postObject(this.schemaNameEducation, "GetCourse", entity);
  }

  getOldCourseLessons(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetOldCourseLessons",
      entity,
      cancelToken
    );
  }

  getContentDocuments(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetContentDocuments",
      entity,
      cancelToken
    );
  }

  getContentDocument(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetContentDocument",
      entity
    );
  }

  getDocumentList(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetDocumentList",
      entity,
      cancelToken
    );
  }

  getLibraryTestList(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetLibraryTestList",
      entity,
      cancelToken
    );
  }

  addNewContentDocumentTmp(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "AddNewContentDocumentTmp",
      entity
    );
  }

  updateContentDocument(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "UpdateContentDocument",
      entity
    );
  }

  updateContentDocumentVimeo(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "UpdateContentDocumentVimeo",
      entity
    );
  }

  getCourseUsingList(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetCourseUsingList",
      entity
    );
  }

  GetCourseTemplateList(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetCourseTemplateList",
      entity
    );
  }

  getCourseTemplate(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetCourseTemplate",
      entity
    );
  }

  updateCourseTemplate(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "UpdateCourseTemplate",
      entity
    );
  }

  updateCourseImageSet(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "UpdateCourseImageSet",
      entity
    );
  }

  getDashboard(entity) {
    return this.postObject(this.schemaNameEducation, "GetDashboard", entity);
  }

  PostDelete(id, url, guidID) {
    let entity = "";

    if (guidID) {
      entity = {
        data: {
          id: id,
          guidID: guidID,
        },
      };
    } else {
      entity = {
        id: id,
      };
    }

    return this.postToUrl(url, entity);
  }

  PostMultiDelete(data, url) {
    let entity = {
      data: data,
    };
    return this.postToUrl(url, entity);
  }

  GetSelectData(
    url,
    page,
    pageSize,
    searchText,
    parentID,
    defaultSelectedValue,
    cancelToken
  ) {
    let entity = {
      //todo: WebApiCollectionRequest kaldırıldığında. data dışındali page kaldırılmalı.
      page: page,
      pageSize: pageSize,
      //********************************************************** */
      data: {
        name: searchText,
        parentID: parentID,
        defaultSelectedValue: defaultSelectedValue,
        page: page,
        pageSize: pageSize,
      },
    };
    return this.postToUrl(url, entity, cancelToken);
  }

  getCourseLesson(entity) {
    return this.postObject(this.schemaNameEducation, "GetCourseLesson", entity);
  }

  cloneCourseLesson(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "CloneCourseLesson",
      entity
    );
  }

  getChooseCompanyList(entity, cancelToken) {
    return this.postObject(
      this.schemaNameAccount,
      "GetChooseCompanyList",
      entity,
      cancelToken
    );
  }

  loginWithToken(entity) {
    return this.postObject(this.schemaNameAccount, "LoginWithToken", entity);
  }

  login(entity) {
    return this.postObject(this.schemaNameAccount, "Login", entity);
  }

  getJourneyList(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetJourneyList",
      entity,
      cancelToken
    );
  }

  getSubJourneyList(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetSubJourneyList",
      entity,
      cancelToken
    );
  }

  getCourseImageSetList(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "getCourseImageSetList",
      entity
    );
  }

  getAddNewJourney(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetAddNewJourney",
      entity
    );
  }

  updateJourney(entity) {
    return this.postObject(this.schemaNameEducation, "UpdateJourney", entity);
  }

  addMultiTag(entity) {
    return this.postObject(this.schemaNameEducation, "AddMultiTag", entity);
  }

  loginQrCodeText(entity) {
    return this.postObject(this.schemaNameAccount, "LoginQrCodeText", entity);
  }

  loginTokenUrl(entity) {
    return this.postObject(this.schemaNameAccount, "LoginTokenUrl", entity);
  }

  addNewLessonTab(entity) {
    return this.postObject(this.schemaNameEducation, "AddNewLessonTab", entity);
  }
  getFromLibrary(entity) {
    return this.postObject(this.schemaNameEducation, "GetFromLibrary", entity);
  }
  getVimeoUploadTicket(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetVimeoUploadTicket",
      entity
    );
  }

  updateGuestName(entity) {
    return this.postObject(this.schemaNameAccount, "UpdateGuestName", entity);
  }

  getLiveRoomDashboard(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetLiveRoomDashboard",
      entity
    );
  }

  getCalendarLessons(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetCalendarLessons",
      entity,
      cancelToken
    );
  }

  endLiveRoomFromCalendar(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "EndLiveRoomFromCalendar",
      entity
    );
  }

  AddNewVimeoDocument(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "AddNewVimeoDocument",
      entity
    );
  }
  AddNewDocument(entity) {
    return this.postObject(this.schemaNameEducation, "AddNewDocument", entity);
  }
  deleteLessonTab(entity) {
    return this.postObject(this.schemaNameEducation, "deleteLessonTab", entity);
  }
  GetLessonTab(entity) {
    return this.postObject(this.schemaNameEducation, "GetLessonTab", entity);
  }

  UpdateLessonTab(entity) {
    return this.postObject(this.schemaNameEducation, "UpdateLessonTab", entity);
  }
  startLiveRoomFromCalendar(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "StartLiveRoomFromCalendar",
      entity
    );
  }

  changeConnectedUserStatus(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "ChangeConnectedUserStatus",
      entity
    );
  }

  getCalendarList(entity) {
    return this.postObject(
      this.schemaNameOrganization,
      "GetCalendarList",
      entity
    );
  }

  liveRoomEnd(entity) {
    return this.postObject(this.schemaNameEducation, "LiveRoomEnd", entity);
  }

  getTagList(entity) {
    return this.postObject(this.schemaNameEducation, "GetTagList", entity);
  }

  getSubTagList(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetSubTagList",
      entity,
      cancelToken
    );
  }

  getTagDataTypeList(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetTagDataTypeList",
      entity
    );
  }

  getFilterTagList(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetFilterTagList",
      entity,
      cancelToken
    );
  }

  updateFilterTagList(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "UpdateFilterTagList",
      entity
    );
  }

  getLiveRoomLinkFromCalendar(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetLiveRoomLinkFromCalendar",
      entity
    );
  }

  showLiveRoom(entity) {
    return this.postObject(this.schemaNameEducation, "ShowLiveRoom", entity);
  }

  createLiveRoom(entity) {
    return this.postObject(this.schemaNameEducation, "CreateLiveRoom", entity);
  }

  checkIsEditAuthority(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "CheckIsEditAuthority",
      entity
    );
  }

  getUserBookmarks(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetUserBookmarks",
      entity
    );
  }

  getTestList(entity) {
    return this.postObject(this.schemaNameEducation, "GetTestList", entity);
  }

  getTestListLazyLoad(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetTestListLazyLoad",
      entity
    );
  }

  getTest(entity) {
    return this.postObject(this.schemaNameEducation, "GetTest", entity);
  }

  getTestRelatedCourses(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetTestRelatedCourses",
      entity
    );
  }

  addNewTest(entity) {
    return this.postObject(this.schemaNameEducation, "addNewTest", entity);
  }

  updateTest(entity) {
    return this.postObject(this.schemaNameEducation, "UpdateTest", entity);
  }
  AddMultiTest(entity) {
    return this.postObject(this.schemaNameEducation, "AddMultiTest", entity);
  }

  AddMultiQuestion(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "AddMultiQuestion",
      entity
    );
  }

  updateQuestionPdfToImage(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "UpdateQuestionPdfToImage",
      entity
    );
  }

  AddMultiQuestionv2(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "AddMultiQuestionv2",
      entity
    );
  }

  DeleteQuestion(entity) {
    return this.postObject(this.schemaNameEducation, "DeleteQuestion", entity);
  }
  TestQuestionEdit(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "TestQuestionEdit",
      entity
    );
  }

  getIsEditAuthority(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetIsEditAuthority",
      entity
    );
  }

  getJourneyUserList(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetJourneyUserList",
      entity,
      cancelToken
    );
  }

  getJourneyUserListLazyLoad(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetJourneyUserListLazyLoad",
      entity
    );
  }

  getJourneyUserDetail(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetJourneyUserDetail",
      entity
    );
  }

  getSubJourneyUserDetail(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetSubJourneyUserDetail",
      entity
    );
  }

  getOtherLessons(entity) {
    return this.postObject(this.schemaNameEducation, "GetOtherLessons", entity);
  }

  getStudentOtherLessonsStar(entity) {
    return this.postObject(
      this.schemaNameEducation,
      "GetStudentOtherLessonsStar",
      entity
    );
  }

  getLessonDetailV2LazyLoad(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetLessonDetailV2LazyLoad",
      entity,
      cancelToken
    );
  }

  getNextLesson(entity, cancelToken) {
    return this.postObject(
      this.schemaNameEducation,
      "GetNextLesson",
      entity,
      cancelToken
    );
  }
}
