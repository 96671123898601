import React, { useEffect, useState, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Service from "../Service";
import PageNotFound from "../Shared/partnerComponents/messages/pageNotFound";
import LoadingCorporate from "../Shared/partnerComponents/loading/loadingCorporate";
import languageLocalStorageControl from "../Shared/utils/language/languageLocalStorageControl";
import {
  RouterPrivate,
  RouterMembership,
  DenemeEkrani,
} from "./routerComponents";
import RouterPublic from "./routerPublic";
import { useDispatch, useSelector } from "react-redux";
import {
  getTranslationResource,
  translationData,
} from "../Store/general/translationResourceSlice";
import { sendRequestTranslateToBackend } from "../Store/general/translationPostSlice";
import VimeoIframe from "../Components/public/vimeoIframe";

const Router = () => {
  const [loading, setLoading] = useState(true);
  const [userError, setUserError] = useState(false);
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const { translations } = useSelector(translationData);

  useEffect(() => {
    const service = new Service();

    fetch("/config.txt")
      .then((r) => r.text())
      .then((text) => {
        //service api adresi

        const textJson = JSON.parse(text);
        global.siteType = textJson?.siteType;
        global.apiURL = textJson?.apiURL;
        global.chatApiURL = textJson?.chatApiURL;
        global.courseExtend = textJson?.courseExtend; // 0: Kapalı || 1: Açık
        global.welcomePageUrl = textJson?.welcomePageUrl;

        service.configuration.initApp({}).then((initApp) => {
          if (initApp?.data && !initApp.hasFailed) {
            global.isDemoFinish = initApp.data.isDemoFinish;
            global.isCompanyPlanFinished = initApp.data.isCompanyPlanFinished; // company plan bitiş tarihinde yenileme yapılmamış ise true döner ve sayfalar kapatılarak planınız bitmiştir mesajı gösterilir.

            // ilk dil kontrolü
            languageLocalStorageControl();

            //globala yazılacaklar
            global.isLoggedIn = initApp.data.isLoggedIn;
            global.config = {
              languages: initApp.data.languages,
            };
            global.GoogleClientID = initApp.data.google;
            global.FacebookAppID = initApp.data.facebook;
            global.MicrosoftTenatID = initApp.data.microsoft;
            global.MicrosoftAppID = initApp.data.microsoftContinue;

            dispatch(getTranslationResource());

            setLoading(false);
          } else {
            errorControl();
          }
        });
      });
  }, [dispatch]);

  // translation kelimelerini her sayfa değiştiğinde yolluyor
  useEffect(() => {
    dispatch(sendRequestTranslateToBackend());
  }, [dispatch, pathname]);

  const errorControl = () => {
    setUserError(true);
    setLoading(false);
  };

  if (loading || !translations) {
    return <LoadingCorporate />;
  } else if (userError) {
    return <PageNotFound msgCode={500} logoUrl="/" />;
  }

  return (
    <Suspense fallback={<LoadingCorporate />}>
      <Routes>
        <Route path="/login/*" element={<RouterMembership />} />
        <Route path="/deneme" exact element={<DenemeEkrani />} />
        <Route path="/vimeoFrame/:id" element={<VimeoIframe />} />
        <Route path="/page/*" element={<RouterPublic />} />
        <Route path="/*" element={<RouterPrivate />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
