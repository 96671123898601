import { useEffect } from "react";
import LayoutPublicHeader from "./layoutPublicHeader";
import LayoutPublicMenuFooter from "./layoutPublicMenuFooter";
import { Outlet } from "react-router-dom";
import { siteType } from "../../utils/appSettings";

const LayoutPublic = () => {
  useEffect(() => {
    if (siteType() === "Live")
      window.location.href = "https://welcome.ucan.academy/";
    else window.location.href = "https://test-welcome.ucan.academy/";
  }, []);
  return (
    <div className="layoutPublic">
      {/* <LayoutPublicHeader />
      <div className="layoutPublic-container">
        <main className="layoutPublic-container-main ">
          {" "}
          <Outlet />{" "}
        </main>
      </div>
      <LayoutPublicMenuFooter /> */}
    </div>
  );
};

export default LayoutPublic;
