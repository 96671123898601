import baseService from "./baseService";

export default class Education extends baseService {
  constructor() {
    super();
    this.schemaName = "Education";
    this.schemaNameReact = "React/Education";
  }

  getDashboard(entity) {
    return this.postObject(this.schemaName, "GetDashboard", entity);
  }

  getUserEducations(entity) {
    return this.postObject(this.schemaName, "GetStudentCourseList", entity);
  }

  getUserCourses(entity) {
    return this.postObject(this.schemaName, "GetUserCourses", entity);
  }

  updateCourse(entity) {
    return this.postObject(this.schemaName, "UpdateCourse", entity);
  }

  getLessonDetail(entity) {
    return this.postObject(this.schemaName, "GetLessonDetail", entity);
  }

  getDashboardUserTest(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetDashboardUserTest",
      entity
    );
  }

  getLiveRoomDashboard(entity) {
    return this.postObject(this.schemaName, "GetLiveRoomDashboard", entity);
  }
  getAnalytics6(entity) {
    return this.postObject(this.schemaNameReact, "GetAnalytics6", entity);
  }
  sendTestAnswer(entity) {
    return this.postObject(this.schemaNameReact, "SendTestAnswer", entity);
  }

  SendTestAnswerLayzLoad(entity) {
    return this.postObject(
      this.schemaNameReact,
      "SendTestAnswerLayzLoad",
      entity
    );
  }

  testAgainSolved(entity) {
    return this.postObject(this.schemaNameReact, "TestAgainSolved", entity);
  }

  updateBookmark(entity) {
    return this.postObject(this.schemaName, "UpdateBookmark", entity);
  }

  updateLike(entity) {
    return this.postObject(this.schemaName, "UpdateLike", entity);
  }

  showLiveRoom(entity) {
    return this.postObject(this.schemaName, "ShowLiveRoom", entity);
  }

  getTeacherLiveRoomReport(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetTeacherLiveRoomReport",
      entity
    );
  }

  getStudentLiveRoomReport(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetStudentLiveRoomReport",
      entity
    );
  }

  GetReportParticipants(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetReportParticipants",
      entity
    );
  }

  ExportLiveRoomReportExcel(entity) {
    return this.postObject(
      this.schemaNameReact,
      "ExportLiveRoomReportExcel",
      entity
    );
  }

  getUserCalendarList(entity) {
    return this.postObject(this.schemaNameReact, "GetUserCalendarList", entity);
  }

  AddQuestionWithOption(entity) {
    return this.postObject(
      this.schemaNameReact,
      "AddQuestionWithOption",
      entity
    );
  }
  checkPdfToImage(entity) {
    return this.postObject(this.schemaNameReact, "CheckPdfToImage", entity);
  }

  updatePdfToImage(entity) {
    return this.postObject(this.schemaNameReact, "UpdatePdfToImage", entity);
  }

  updateJourneyV2(entity) {
    return this.postObject(this.schemaNameReact, "UpdateJourneyV2", entity);
  }

  getJourneyDetail(entity) {
    return this.postObject(this.schemaNameReact, "GetJourneyDetail", entity);
  }

  getSubJourneyDetail(entity) {
    return this.postObject(this.schemaNameReact, "GetSubJourneyDetail", entity);
  }

  getSubJourneyTestList(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "GetSubJourneyTestList",
      entity,
      cancelToken
    );
  }

  getSubJourneyTestListLazyLoad(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "GetSubJourneyTestListLazyLoad",
      entity,
      cancelToken
    );
  }

  getSubJourneyEvaluationResult(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "GetSubJourneyEvaluationResult",
      entity,
      cancelToken
    );
  }

  getSubJourneyEvaluationResultHeader(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetSubJourneyEvaluationResultHeader",
      entity
    );
  }
  addJourneyUserStatus(entity) {
    return this.postObject(
      this.schemaNameReact,
      "AddJourneyUserStatus",
      entity
    );
  }

  deleteJourney(entity) {
    return this.postObject(this.schemaNameReact, "DeleteJourney", entity);
  }

  deleteJourneyTeacher(entity) {
    return this.postObject(
      this.schemaNameReact,
      "DeleteJourneyTeacher",
      entity
    );
  }

  restoreConnectedCourse(entity) {
    return this.postObject(
      this.schemaNameReact,
      "RestoreConnectedCourse",
      entity
    );
  }

  getRestoreConnectedCourseNames(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetRestoreConnectedCourseNames",
      entity
    );
  }

  RestoreCourseLesson(entity) {
    return this.postObject(this.schemaNameReact, "RestoreCourseLesson", entity);
  }

  CourLessonSorter(entity) {
    return this.postObject(this.schemaNameReact, "CourLessonSorter", entity);
  }

  LessonContainerSorter(entity) {
    return this.postObject(
      this.schemaNameReact,
      "LessonContainerSorter",
      entity
    );
  }

  addMultiQuestionV3(entity) {
    return this.postObject(this.schemaNameReact, "AddMultiQuestionV3", entity);
  }

  checkVimeoDocumentStatus(entity) {
    return this.postObject(
      this.schemaNameReact,
      "CheckVimeoDocumentStatus",
      entity
    );
  }

  getDashboardAnnouncement(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetDashboardAnnouncement",
      entity
    );
  }

  getDashboardStories(entity) {
    return this.postObject(this.schemaNameReact, "GetDashboardStories", entity);
  }

  getDashboardStatistics(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetDashboardStatistics",
      entity
    );
  }

  getDashboardCourses(entity) {
    return this.postObject(this.schemaNameReact, "GetDashboardCourses", entity);
  }

  getDashboardStatisticsFooter(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetDashboardStatisticsFooter",
      entity
    );
  }

  addNewCourseSelectTemplate(entity) {
    return this.postObject(
      this.schemaNameReact,
      "AddNewCourseSelectTemplate",
      entity
    );
  }

  updateEvaluation(entity) {
    return this.postObject(this.schemaNameReact, "UpdateEvaluation", entity);
  }

  updateUserTestResult(entity) {
    return this.postObject(
      this.schemaNameReact,
      "UpdateUserTestResult",
      entity
    );
  }

  UpdateUserTestResultLazyLoad(entity) {
    return this.postObject(
      this.schemaNameReact,
      "UpdateUserTestResultLazyLoad",
      entity
    );
  }

  sendTestAnswerSingle(entity) {
    return this.postObject(
      this.schemaNameReact,
      "SendTestAnswerSingle",
      entity
    );
  }

  UpdateProgressInTab(entity) {
    return this.postObject(this.schemaNameReact, "UpdateProgressInTab", entity);
  }

  getSubCoursesAndLessons(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetSubCoursesAndLessons",
      entity
    );
  }

  updateSubJourney(entity) {
    return this.postObject(this.schemaNameReact, "UpdateSubJourney", entity);
  }

  getExamListForSelect(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "GetExamListForSelect",
      entity,
      cancelToken
    );
  }

  updateCourseLessonEvulationExam(entity) {
    return this.postObject(
      this.schemaNameReact,
      "UpdateCourseLessonEvulationExam",
      entity
    );
  }

  updateTestSection(entity) {
    return this.postObject(this.schemaNameReact, "UpdateTestSection", entity);
  }

  clearTestSections(entity) {
    return this.postObject(this.schemaNameReact, "ClearTestSections", entity);
  }
  GetJourneyEvaluationReport(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetJourneyEvaluationReport",
      entity
    );
  }

  getOldCoursesWithFilter(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "GetOldCoursesWithFilter",
      entity,
      cancelToken
    );
  }

  getSubCoursesAndLessonsWithFilter(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "GetSubCoursesAndLessonsWithFilter",
      entity,
      cancelToken
    );
  }

  checkPdfDocumentExists(entity) {
    return this.postObject(
      this.schemaNameReact,
      "CheckPdfDocumentExists",
      entity
    );
  }

  checkVideoDocumentExists(entity) {
    return this.postObject(
      this.schemaNameReact,
      "CheckVideoDocumentExists",
      entity
    );
  }

  checkVimeoDocumentExists(entity) {
    return this.postObject(
      this.schemaNameReact,
      "CheckVimeoDocumentExists",
      entity
    );
  }
  GetJourneyEvaluationReportLazy(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetJourneyEvaluationReportLazy",
      entity
    );
  }

  GetJourneyEvaluationReportUserActivate(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetJourneyEvaluationReportUserActivate",
      entity
    );
  }
  GetJourneyEvaluationReportUserTestActivate(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetJourneyEvaluationReportUserTestActivate",
      entity
    );
  }

  checkResetJourneyStatistic(entity) {
    return this.postObject(
      this.schemaNameReact,
      "CheckResetJourneyStatistic",
      entity
    );
  }

  getTags(entity) {
    return this.postObject(this.schemaNameReact, "GetTags", entity);
  }

  getTag(entity) {
    return this.postObject(this.schemaNameReact, "GetTag", entity);
  }
  updateTag(entity) {
    return this.postObject(this.schemaNameReact, "UpdateTag", entity);
  }

  updateJourneyTestResult(entity) {
    return this.postObject(
      this.schemaNameReact,
      "UpdateJourneyTestResult",
      entity
    );
  }

  getJourneyReport1(entity) {
    return this.postObject(this.schemaNameReact, "GetJourneyReport1", entity);
  }

  getJourneyReport1Excel(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetJourneyReport1Excel",
      entity
    );
  }

  getJourneyReport1Detail(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetJourneyReport1Detail",
      entity
    );
  }
}
