export function tagListFormAddModel(requestData) {
  const data = requestData ? requestData.data : false;

  if (data && !requestData.hasFailed) {
    const { tags, totalDataCount, pageSize, pageNumber } = data;
    return {
      item: {
        tags,
        totalDataCount,
        pageSize,
        pageNumber,
      },
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}
