import {
  initialValuesDate,
  initialValuesTime,
} from "../../../utils/dateFormatter/dateRegex";

export function editCalendarModel(requestData) {
  const data = requestData ? requestData.data : false;

  if (data && !requestData.hasFailed) {
    return {
      calendar: getCalendar(data),
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}

function getCalendar(data) {
  return {
    id: data.id,
    name: data.name,
    courseLessonID: data.courseLessonID,
    courseLessonName: data.courseLessonName,
    description: data.description,

    startDate: initialValuesDate(data.startDate),
    startTime: initialValuesTime(data.startTime),
    meetingTime: data.meetingTime,

    teacherList: data.teacherList,
    userAndGroupList: data.userAndGroupList,
    meetingGuestCount: data.MeetingGuestCount,
  };
}
