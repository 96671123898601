import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ClarificationText from "../Components/public/clarificationText";
import PrivacyPolicy from "../Components/public/privacyPolicy";
import LayoutPublic from "../Shared/layout/layoutPublic";
import LoadingCorporate from "../Shared/partnerComponents/loading/loadingCorporate";
import { About, Contact, RequestDemo, Welcome } from "./routerComponents";

const RouterPublic = () => {
  return (
    <Suspense fallback={<LoadingCorporate />}>
      <Routes>
        <Route path="*" element={<LayoutPublic />}>
          {/* <Route path={`/about`} element={<About />} />
          <Route path={`/welcome`} element={<Welcome />} />
          <Route path={`/demoRequest`} element={<RequestDemo />} />
          <Route path={`/contact`} element={<Contact />} />
          <Route path={`/clarificationText`} element={<ClarificationText />} />
          <Route path={`/PrivacyPolicy`} element={<PrivacyPolicy />} /> */}
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RouterPublic;
